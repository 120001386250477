import { AppScreen } from "@/stackflow/components";
import TimeTableGrid from "@components/common/TimeTableGrid";
import leftIcon from "@assets/left.png";
import shareIcon from "@assets/share.png";

import {
  Button,
  ButtonWrapper,
  Description,
  InfoWrapper,
  Title,
  Container,
  TitleLayout,
  PreviewResult,
  PreviewResultText,
} from "./index.css";
import { Spacing } from "@components/common/Spacing";
import { usePathParams } from "@/stackflow/hooks/usePathParams";
import { useFlow } from "@/stackflow";
import useGetMeetDetail, {
  MeetDetailResponse,
} from "@/apis/hooks/useGetMeetDetail";
import AsyncBoundary from "@components/common/AsyncBoundary/AsyncBoundary";
import usePutMemberMeetInfo from "@/apis/hooks/usePutMemberMeetInfo";

import { useState } from "react";
import { enqueueSnackbar } from "notistack";

import FullSpinnerView from "@components/common/Spinner/FullSpinnerView";
import useLogin from "@hooks/useLogin";
import VoteSetInfoLoginBottomSheet from "@components/VoteSetInfoLoginBottomSheet";
import OverlaySpinnerView from "@components/common/Spinner/OverlaySpinnerView";
import { DefaultTimeGridData } from "@utils/transformData";
import { copyToClipboard } from "@utils/link";
import { Helmet } from "react-helmet-async";
import { WEB_PATH } from "@/apis/constants";

const VotePage = () => {
  const { meetId } = usePathParams();

  const { meet } = useGetMeetDetail(meetId);

  const loaded = meet?.success === true && meet?.data;

  return (
    <AsyncBoundary pendingFallback={<FullSpinnerView />}>
      {loaded ? <VotePageInner meet={meet.data.meet} /> : <FullSpinnerView />}
    </AsyncBoundary>
  );
};

const VotePageInner = ({ meet }: { meet: MeetDetailResponse["meet"] }) => {
  const [isOpenLoginBottomSheet, setIsOpenLoginBottomSheet] = useState(false);
  const { meetId } = usePathParams();

  const [selectedSlots, setSelectedSlots] = useState<Record<string, boolean>>(
    {}
  );
  const { nickname, setNickname } = useLogin({
    handleOpenLoginBottomSheet: () => setIsOpenLoginBottomSheet(true),
    setDefaultSelectedSlots: (nickname) => {
      setSelectedSlots(DefaultTimeGridData(nickname, meet.members));
    },
  });
  const { push } = useFlow();
  const { mutate, isPending } = usePutMemberMeetInfo({
    onError: () => {
      enqueueSnackbar("일정 등록에 실패했어요. 잠시후 다시 시도해주세요.", {
        variant: "default",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        preventDuplicate: true,
      });
    },
    onSuccess: (data) => {
      push("ResultPage", {
        meetId,
      });
    },
  });

  const handleMoveToResult = () => {
    if (!nickname) {
      return;
    }

    const availableTime = Object.entries(selectedSlots);

    const filteredAvailableTime = availableTime
      .filter((el) => el[1] === true)
      .map((el) => {
        const [date, hour] = el[0].split("-");
        return {
          date,
          hour: Number(hour),
        };
      });

    mutate({
      meetId,
      name: nickname,
      availableTime: filteredAvailableTime ?? [],
    });
  };

  const handlePreviewResult = () => {
    push("ResultPage", {
      meetId,
    });
  };

  return (
    <>
      <Helmet>
        <title>{`${meet?.title || "모임"} | 만날시간 선택하기`}</title>
        <meta
          name="description"
          content={`'${meet?.title}' 만날 시간을 등록해주세요. 현재까지 ${meet?.members?.length}명이 참여했습니다.`}
        />

        {/* Open Graph */}
        <meta
          property="og:title"
          content={`${meet?.title || "모임"} | 만날시간 선택하기`}
        />
        <meta
          property="og:description"
          content={`'${meet?.title}' 만날 시간을 선택해주세요. 현재까지 ${meet?.members?.length}명이 참여했습니다.`}
        />
        <meta
          property="og:url"
          content={`https://wemeettime.com/vote/${meet?._id}`}
        />

        {/* Twitter */}
        <meta
          name="twitter:title"
          content={`${meet?.title || "모임"} | 만날시간 선택하기`}
        />
        <meta
          name="twitter:description"
          content={`${meet?.title} 만날 시간을 선택해주세요. 현재까지 ${meet?.members?.length}명이 참여했습니다.`}
        />
      </Helmet>

      <AppScreen
        appBar={{
          title: meet.title,
          closeButton: {
            render: () => (
              <img
                src={leftIcon}
                width={30}
                onClick={() => {
                  push(
                    "HomePage",
                    {},
                    {
                      animate: false,
                    }
                  );
                }}
              />
            ),
          },
          renderRight: () => (
            <img
              src={`${WEB_PATH}/share.png`}
              width={24}
              onClick={() => {
                copyToClipboard(window.location.href);
                enqueueSnackbar("링크가 복사되었어요!", {
                  variant: "default",
                  autoHideDuration: 800,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  preventDuplicate: true,
                });
              }}
            />
          ),
        }}
        accessoryBar={
          <ButtonWrapper>
            <Button enable onClick={handleMoveToResult}>
              완료
            </Button>
          </ButtonWrapper>
        }
      >
        <Container>
          {isPending && <OverlaySpinnerView />}
          {isOpenLoginBottomSheet && (
            <VoteSetInfoLoginBottomSheet
              handleOpenLoginBottomSheet={() => {
                setIsOpenLoginBottomSheet(false);
              }}
              handleSetNickname={(nickname) => {
                setNickname(nickname);
              }}
            />
          )}
          <InfoWrapper>
            <PreviewResult>
              <PreviewResultText onClick={handlePreviewResult}>
                결과 미리보기
              </PreviewResultText>
            </PreviewResult>
            <TitleLayout>
              <Title>
                {nickname ? (
                  <>
                    {nickname}님의
                    <br />
                    참여 가능 시간을 선택해주세요
                  </>
                ) : (
                  "참여 가능 시간을 선택해주세요"
                )}
              </Title>
            </TitleLayout>
            <Spacing height={10} />
            <Description>
              날짜와 시간 영역을 스크롤하며 이동할 수 있어요.
            </Description>
          </InfoWrapper>
          <TimeTableGrid
            selectedSlots={selectedSlots}
            setSelectedSlots={setSelectedSlots}
            settedOriginStartDate={meet.openDate.startDate}
            settedOriginEndDate={meet.openDate.endDate}
            settedOriginStartTime={meet.openDate.startTime}
            settedOriginEndTime={meet.openDate.endTime}
          />
        </Container>
      </AppScreen>
    </>
  );
};

export default VotePage;
