import { ReactElement, useEffect } from "react";
import { SnackbarProvider } from "notistack";
import { HelmetProvider } from "react-helmet-async";
import { Stack } from "./stackflow";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      staleTime: 0,
    },
  },
});

function App(): ReactElement {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <Stack />
        </SnackbarProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
